import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Link from '/src/components/LocalizedLink';
import ForthcomingCourses from '../components/ForthcomingCourses';
import AplicationProcess from '../components/AplicationProcess';
import ReviewsSection from '../components/ReviewsSection';
import Faq from '../components/Faq';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';
import iconCalendar from '../assets/images/svg/calendar.svg';
import iconClock from '../assets/images/svg/clock.svg';
import iconTick from '../assets/images/svg/tick.svg';

import iconAccomodation from '../assets/images/student-support/Accommodation (1).svg';
import iconStudentVisa from '../assets/images/student-support/Student visas (1).svg';
import iconCulture from '../assets/images/student-support/Culture.svg';
import iconBureaucracy from '../assets/images/student-support/Bureaucracy.svg';

import imgBenefit1 from '../assets/images/teaching-young-learners/Practical Teaching Experience .svg';
import imgBenefit2 from '../assets/images/teaching-young-learners/Increase Your Employability  .svg';
import imgBenefit3 from '../assets/images/teaching-young-learners/Practical Classroom Experience  (2).svg';
import imgBenefit5 from '../assets/images/teaching-young-learners/Post-Grad Job Prospects 2 (2).svg';
import imgReview from '../assets/images/teaching-young-learners/Teaching Young Learners - Harry Lawrence.png';
import iconQuotes from '../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import imgTrinityCertCourse from '../assets/images/other-popular-courses/Trinity CertTESOL Course (1).png';
import imgIntensiveSpanish from '../assets/images/other-popular-courses/Intensive Spanish Course (1).png';
import imgDevelopingTeacherCourse from '../assets/images/other-popular-courses/Developing Teacher Course.png';

import fileTrinityCertTESOLSyllabus from '../assets/files/CertTESOL-syllabus-Jan-2016.pdf';
import fileTEFLIberiaBrochure from '../assets/files/TEFL Iberia brochure web.pdf';

import '../assets/styles/pages/teaching-young-learners-page.scss';

const listUnit3 = `
                <span>Practical activities for young learners:</span>
                <ul class='faq-list'>
                    <li class='faq-list__item'>Optimal conditions for young learners.</li>
                    <li class='faq-list__item'>Choosing the right activities.</li>
                    <li class='faq-list__item'>Theme-based lessons and ideas.</li>
                    <li class='faq-list__item'>Stories, games, quizzes, songs.</li>
                    <li class='faq-list__item'>Using the internet with young learners</li>
                </ul>
                `;
const listUnit2 = `
                <span>Child development and teaching young learners</span>
                <ul class='faq-list'>
                    <li class='faq-list__item'>Different types of development</li>
                    <li class='faq-list__item'>Language acquisition</li>
                    <li class='faq-list__item'>Behaviour management</li>
                    <li class='faq-list__item'>Planning your lessons</li>
                </ul>
                `;
const listUnit1 = `
                <span>Introduction to teaching young learners</span>
                <ul class='faq-list'>
                    <li class='faq-list__item'>Characteristics of a young learner</li>
                    <li class='faq-list__item'>Differences between adult and young learners</li>
                    <li class='faq-list__item'>Working with 5-7 and 8-10 year olds</li>
                </ul>
                `;
const listUnit4 = `
                <span>Teaching very young learners</span>
                <ul class='faq-list'>
                    <li class='faq-list__item'>Moving into early years teaching</li>
                    <li class='faq-list__item'>Different stages of development</li>
                    <li class='faq-list__item'>Managing very young learners</li>
                </ul>
                `;
const listFaq3 = `
                <span>To be able to do this course you must have completed either:</span>
                <ul class='faq-list'>
                    <li class='faq-list__item'>The 160-hour Trinity CertTESOL course</li>
                    <li class='faq-list__item'>Any other introductory course which has teaching practice (TEFL, CELTA)</li>
                </ul>
                `;
const faqs = [
  {
    id: 1,
    title: 'Will I get to practice teaching with real students?',
    description:
      'Recomendamos un mínimo de 4 meses seguidos del mismo nivel antes de subir. Los tutores del curso te informarán si puedes subir de nivel.',
  },
  {
    id: 2,
    title: 'Do I need to do a DBS / criminal background check done before doing the course?',
    description:
      'No, as you will always be in the supervision of an experienced and qualified teacher. However, if you intend to work with children in Spain, you’ll need to get an ‘antecedentes penales’ certificate. ',
  },
  {
    id: 3,
    title: 'What are the course entry requirements?',
    description: listFaq3,
  },
];

const cardData = [
  {
    course: 'Trinity CertTESOL',
    from: '5 October 2020',
    to: '30 October 2020',
    price: '€1900',
  },
];

const TeachingYoungLearners = ({ data }) => {
  const allPrismicCourses = data.allPrismicCourses.edges;
  const [isMore, setIsMore] = useState(false);

  return (
    <Layout
      title={{ id: 'teachingYoungLearners.seo.title' }}
      description={{ id: 'teachingYoungLearners.seo.description' }}
    >
      <div className="teaching-young-learners-page">
        <section className="c-first-section c-first-section--sm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <div className="c-first-section__schedule">
                  <div className="item__wrapper">
                    <img src={iconCalendar} alt="" />
                    <span className="c-text-14">3 days</span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconClock} alt="" />
                    <span className="c-text-14">20 hours</span>
                  </div>
                </div>
                <h1 className="c-first-section__title">Teaching Young Learners Course</h1>
                <p className="c-first-section__description">
                  A 3-day practical course to learn to teach children and teenagers.
                </p>
                <div className="c-first-section__btns">
                  {/* <a href="/apply/" className="c-btn c-btn--red">
                    Apply Now
                    <IconArrowRight />
                  </a> */}
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <FormattedMessage id="menu.applyNow" />
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">Discover a Rewarding New Career</h2>
                <p className="c-text-18 description second-section__l">
                  There is an equal if not greater demand for teaching young learners compared to
                  adult classes in Barcelona. As a new teacher you will come across countless offers
                  of classes with kids and teens – make yourself more employable and give yourself
                  an edge in job interviews with our Teaching Young Learners course.
                </p>
                <div className="c-benefit-list second-section__l">
                  <h4 className="c-text-16">This course is ideal for you if:</h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You have never taught children before. </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want to maximise your employment opportunities in Barcelona.
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You enjoy working with children.</p>
                  </div>
                </div>
                <div className="c-benefit-list second-section__l mt-50">
                  <h4 className="c-text-16">Course entry requirements:</h4>
                  <div className="benefit">
                    <p className="c-text-16">
                      To be able to do this course you must have completed either:
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">The 160-hour Trinity CertTESOL course</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      Any other introductory course which has teaching practice (TEFL, CELTA)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Gain Confidence </h3>
                      <p className="c-text-14">Get experience engaging with young learners.</p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Increase Your Employability</h3>
                      <p className="c-text-14">Give yourself the edge with this advanced course.</p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Practical Classroom Experience</h3>
                      <p className="c-text-14">
                        You’ll build confidence by teaching groups of real students.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Explore Behaviour Management</h3>
                      <p className="c-text-14">Learn how to manage a class of children.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReview} alt="" className="img-main" />
                    <img src={imgDots} alt="" className="dots" />
                  </div>
                  <div className="block_l__text">
                    <img src={iconQuotes} alt="" />
                    <p>
                      I would recommend this course to anyone wanting to pursue a career in teaching
                      abroad. The staff are amazing and are always on hand to help. Although the
                      course is very intensive, the structure is organised very well, so you can
                      handle the workload. Overall, it’s very enjoyable and you meet lots of amazing
                      people whilst living in Barcelona.
                      <br />
                      <strong>Harry Lawrence</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">What’s included?</h2>
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        3 days of practical learning - workshops, demos, classes.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        6 hours of observation and teaching practice with real learners.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Observation of experienced teachers.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Guided lessons planning.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Experience with a range of ages: 5-16 year old students.
                      </p>
                    </div>
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">Full Price:</h3>
                    <h3 className="c-title-32">300€</h3>
                  </div>
                  <div className="block_r__btns">
                    {/* <Link to="/apply/" className="c-btn c-btn--red">
                      Apply Now
                      <IconArrowRight />
                    </Link> */}
                    <FormattedMessage id="url.apply.index">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--red">
                          <FormattedMessage id="menu.applyNow" />
                          <IconArrowRight />
                        </Link>
                      )}
                    </FormattedMessage>
                    <a
                      href={fileTEFLIberiaBrochure}
                      className="c-btn c-btn--red-border"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Brochure
                      <IconArrowRightRed />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section">
          <div className="container">
            <ForthcomingCourses
              cardData={allPrismicCourses.slice(0, isMore ? allPrismicCourses.length : 4)}
              title={{ id: 'forthcomingCourses.title.upcoming' }}
            />
            {allPrismicCourses.length > 4 ? (
              <button
                onClick={() => setIsMore(!isMore)}
                className="c-btn c-btn--red-border load-more-dates"
              >
                {isMore ? 'Show Less Dates' : 'Load More Dates'}
                <IconArrowRightRed />
              </button>
            ) : null}
          </div>
        </section>
        <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage id="studentSupport.title" />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="studentSupport.description" />
            </p>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.accommodation" />
                  </h3>
                  <FormattedMessage id="url.accommodation">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.studentVisa" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.culture" />
                  </h3>
                  <FormattedMessage id="url.guide-to-living-and-teaching-english-in-barcelona">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <img src={imgDots} className="dots" alt="" />
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.bureaucracy" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">Student support</h2>
            <p className="c-text-18">Learn about our additional services</p>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">Accommodation</h3>
                  <Link to="/accommodation/" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">Student Visa</h3>
                  <Link to="/working-as-a-teacher/student-visas" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">Culture</h3>
                  <Link
                    to="/guide-to-living-and-teaching-english-in-barcelona/"
                    className="c-btn c-btn--white"
                  >
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <img src={imgDots} className="dots" alt="" />
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">Bureaucracy</h3>
                  <Link to="/working-as-a-teacher/student-visas" className="c-btn c-btn--white">
                    Find Out More
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="sixth-section">
          <AplicationProcess />
        </section>
        <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              Start Your Overseas <span className="colored no-link">Adventure Today</span>
            </h2>
            {/* <Link to="/apply/" className="c-btn c-btn--red">
              Sign Up Now
              <IconArrowRight />
            </Link> */}
            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="menu.signUpNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>

        <section className="eigth-section c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">Course Curriculum and Timetable</h3>
                <p className="c-text-18">
                  The Teaching Young Learners Course is 3 days long and takes place immediately
                  after each 1-month Trinity CertTESOL course.{' '}
                  <span className="text-fw-600">
                    The timetable is Monday - Wednesday, 11.00 - 19.00.
                  </span>
                </p>

                <div className="assesment-block">
                  <h4 className="c-title-20">Is English not your native language?</h4>
                  <p className="c-text-16">
                    You need to demonstrate a C1 level in order to be able to do the course.
                  </p>
                  <a
                    href="https://www.tryinteract.com/share/quiz/5fa530f1849de80014ec5fbc"
                    className="c-btn c-btn--red-border"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Take English Assessment
                    <IconArrowRightRed />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <Faq title="Unit 1" description={listUnit1} />
                <Faq title="Unit 2" description={listUnit2} />
                <Faq title="Unit 3" description={listUnit3} />
                <Faq title="Unit 4" description={listUnit4} />
              </div>
            </div>
          </div>
        </section>

        <section className="nineth-section">
          <ReviewsSection
            hideAllReviews
            description={{ id: 'graduates.teflSpanishCourses.description' }}
            title={{ id: 'graduates.teflSpanishCourses.title' }}
          />
        </section>

        <section className="tenth-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">Frequently Asked Questions</h2>
              <FormattedMessage id="url.courses.faq">
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    <FormattedMessage id="btn.viewMoreFaqs" />
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  description="Yes, you will get to teach 3 lessons to students between 5-16 years old."
                  title="Will I get to practice teaching with real students?"
                />
                <Faq description={faqs[1].description} title={faqs[1].title} />
              </div>
              <div className="col-lg-6">
                <Faq description={faqs[2].description} title={faqs[2].title} />
              </div>
            </div>
          </div>
        </section>
        <section className="eleventh-section c-other-courses-section">
          <div className="container">
            <h2 className="c-title-34">
              Other <span className="colored no-link">Popular Courses</span>
            </h2>
            <p className="c-text-18">Take your training to the next level with TEFL Iberia</p>
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.courses.one-month-160-hour-tefl-course.index">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgTrinityCertCourse}
                        alt="imgTrinityCertCourse"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">1650€</span>
                      </p>
                      <p className="c-text-20">Trinity CertTESOL Course</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.courses.developing-teacher-course">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgDevelopingTeacherCourse}
                        alt="imgDevelopingTeacherCourse"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">120€ </span>
                        /month
                      </p>
                      <p className="c-text-20">Developing Teacher Course</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.tefl-spanish-course-barcelona">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgIntensiveSpanish}
                        alt="imgIntensiveSpanish"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">300€ </span>
                        /month
                      </p>
                      <p className="c-text-20">Intensive Spanish Course</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "teaching-young-learners" } } } }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
          }
        }
      }
    }
  }
`;

export default TeachingYoungLearners;
